#tsparticles canvas {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
}

.sectionServices {
  padding: 60px 60px;
  overflow: hidden;
  background-color: rgb(255, 255, 255); 
}


:root {
  --box-shadow-color: rgba(82, 90, 101, 0.1);
  --text-color: #3a3a3a;
  --color-primary: rgb(182, 83, 83); 
}

.icon-box {
  padding: 20px 30px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
  height: 100%; 
  width: 100%; 
  text-align: center;
  color: var(--text-color);
  box-shadow: -1px 5px 20px 0px var(--box-shadow-color);
}

.icon-box .iconServices {
  margin-bottom: 20px;
  padding-top: 10px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  font-size: 48px;
  line-height: 1;
  color: var(--color-primary);
}

.servicio-title {
  margin-bottom: 15px;
  font-size: 24px;
  color: var(--color-primary);
  text-decoration: underline;
}

.me-3{
  font-size: 20px;
  color: #fff;
}

/*estilos porque elegirnos*/
.sectionLanding{
  padding: 60px 60px 0 60px;
  overflow: hidden;
  background: rgb(255, 255, 255);;
}

@media (max-width: 768px) {
  .sectionLanding {
    padding: 60px 0;
  }
}

.section-header h2{
  font-size: 32px;
  position: relative;
  color: var(--color-primary);
}

.section-header{
  text-align: center;
  padding-bottom: 25px;
}

.left {
  text-align: right;
}

.list-wrap {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}

.list-wrap .description h4 {
  color: var(--text-color);
}

.icon {
  width: auto !important; 
  text-align: center;
  height: 100px;
  color: var(--color-primary);
  font-size: 54px;
}

.list-center-wrap {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  height: 100%;
}

.center-icon {
  width: 100%;
  text-align: center;
}

.center-icon img {
  width: 100%;
  border-radius: 20px;
  opacity: 0.9;
}

.center-icon img:hover,
.icon:hover,
.iconEst:hover {
    transform: scale(1.1);
}

.center-icon img, 
.icon,
.iconEst {
  transition: 0.3s;
}

.cardPrecie {
  position: relative;
  height: auto;
  background: var(--light-color);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  margin: 0 auto;
  padding: 40px 20px;
  transition: .5s;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  -ms-transition: .5s;
  -o-transition: .5s;
  overflow: hidden;
  margin-bottom: 20px;
  border: 0;
  box-shadow: -1px 5px 20px 0px var(--box-shadow-color);
}

.text-center {
  text-align: center !important;
}

.title h2 {
  position: relative;
  margin: 20px 0 0;
  padding: 0;
  color: var(--text-color);
  font-size: 28px;
  z-index: 2;
}

.price {
  position: relative;
  z-index: 2;
  color: var(--text-color);
}

.price h4 {
  margin: 0;
  padding: 20px 0;
  color: var(--text-color);
  font-size: 60px;
}

.optionPrice {
  position: relative;
  z-index: 2;
}

.optionPrice ul {
  margin: 0;
  padding: 0;
}

.optionPrice ul li {
  margin: 0 0 10px;
  padding: 0;
  list-style: none;
  color: var(--text-color);
  font-size: 16px;
}

/*estilos estadisticas*/
.call-to-action {
  background: url('../../../public/fonoplexus.jpg') center center;
  background-size: cover;
  padding: 50px 60px;
  border-radius: 15px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  position: relative;
  z-index: 0;
  margin: 0;
}

.iconEst {
  width: auto !important; 
  text-align: center;
  height: 100px;
  color: #ffffff;
  font-size: 80px;
}

.call-to-action::before, .sections-bg::before {
  content: '';
  background: linear-gradient(18deg, #733a3a 0%, #e9a4a4 100%);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  z-index: -1;
}

.stats-counter .stats-item .purecounter {
  padding-right: 0;
  font-size: 36px;
  font-weight: 900;
  margin: 20px 0;
}

.stats-counter .stats-item span {
  font-size: 24px;
  display: block;
  color: #ffffff;
  line-height: 34px;
}

.stats-counter .stats-item p {
  margin: 0;
  font-family: var(--font-primary);
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.call-to-action p {
  color: #fff;
  margin-bottom: 20px;
  max-width: 600px;
  text-align: center;
  margin: 0 auto;
}

.call-to-action p {
  color: #fff;
  margin-bottom: 20px;
}

/*funcionamiento*/
.section-header {
  text-align: center;
}

.funcionamiento-text-left {
  text-align: left !important;
}

.funcionamiento-header h2 {
  font-size: 34px;
  margin-bottom: 20px;
  position: relative;
  color: var(--color-primary);
  font-weight: 400;
}

.sectionLanding .contentFuncionamiento ul {
  list-style: none;
  padding: 0;
  flex-wrap: wrap;
  gap: 10%;
}

.sectionLanding .contentFuncionamiento ul li {
  line-height: 30px;
}


.sectionLanding .contentFuncionamiento ul li p{
  line-height: 20px;
  text-align: justify;
}

.iconoRow {
  color: var(--color-primary);
}

.contentFuncionamiento div{
  display: flex;
  align-items: center;
  gap: 10px
}

.contentFuncionamiento h5{
  margin-bottom: 0 !important;
}

.contenedorMock{
  align-self: center;
}

.imgMock{
  border-radius: 8px;
  box-shadow:  0 8px 16px rgba(0, 0, 0, 0.2);
}