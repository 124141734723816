body {
  background-color: #f2f6fc !important;
  font-family: 'Roboto', sans-serif !important;
  font-size: 0.9rem !important;
  color: #2b2b2b !important;
  line-height: 1.5 !important;
  font-weight: 400 !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.bg-light {
  background-color: #ffffff !important;
}

button,
a,
link,
input,
select,
textarea {
  border-radius: 0px !important;
}

.table> :not(:first-child) {
  border-top: 0 !important;
}

@media (min-width: 1200px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1340px !important;
  }
}

@media (min-width: 576px) {
  .modal-dialog-grande {
    max-width: 2000px !important;
    margin: 10px !important;
  }
}

@media (min-width: 576px) {
  .modal-dialog-pequeno {
    max-width: 700px !important;
  }
}

.button-style {
  font-size: 15px !important;
}

/* INICIO - MODAL POPUP FULL CSS */

.modal-window-internal {
  border-top-right-radius: calc(0.3rem - 1px);
  border-top-left-radius: calc(0.3rem - 1px);
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;

  &:target {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }

  &>div {
    width: 1000px;
    position: absolute;
    top: 27%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 2em;
    padding-bottom: 2em;
    padding-left: 1em;
    padding-right: 1em;
    background: white;
    border: 1px solid #ccc !important;
  }

  header {
    font-weight: bold;
  }

  h1 {
    font-size: 150%;
    margin: 0 0 15px;
  }
}


.modal-window-external {
  border-top-right-radius: calc(0.3rem - 1px);
  border-top-left-radius: calc(0.3rem - 1px);
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;

  &:target {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }

  &>div {
    width: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 2em;
    padding-bottom: 2em;
    padding-left: 1em;
    padding-right: 1em;
    background: white;
    border: 1px solid #ccc !important;
  }

  header {
    font-weight: bold;
  }

  h1 {
    font-size: 150%;
    margin: 0 0 15px;
  }
}

.modal-window-subinternal {
  border-top-right-radius: calc(0.3rem - 1px);
  border-top-left-radius: calc(0.3rem - 1px);
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;

  &:target {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }

  &>div {
    width: 600px;
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 2em;
    padding-bottom: 2em;
    padding-left: 1em;
    padding-right: 1em;
    background: white;
    border: 1px solid #ccc !important;
  }

  header {
    font-weight: bold;
  }

  h1 {
    font-size: 150%;
    margin: 0 0 15px;
  }
}



.modal-close {
  color: #aaa;
  line-height: 70px;
  font-size: 140%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 50px;
  text-decoration: none;

  &:hover {
    color: black;
  }
}

.modal-close-over {
  color: #aaa;
  line-height: 70px;
  font-size: 140%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 50px;
  text-decoration: none;

  &:hover {
    color: black;
  }
}

/* FIN - MODAL POPUP FULL CSS */

.form-switch {
  padding-left: 3.5em !important;
}

/* icono para mostrar clave en inputpassword */
.input-password {
  float: right;
  margin-left: 80px;
  position: relative;
  z-index: 2;
  margin-top: -30px;
  margin-right: 10px
}

/* landing page */
.container-landing {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.image-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 1000px;
}

.image {
  width: 100%;
  height: auto;
  position: relative;
  z-index: -1;
}

.icons-container {
  position: absolute;
  bottom: 125px;
  left: 20%;
  transform: translateX(-50%);
  display: flex;
  gap: 20px;
}

.iconStore {
  width: 150px;
  height: 50px;
}

.subtitle-politicas {
  background: #E38C8C;
  color: white;
  width: auto;
  margin-top: 10px;
  padding-left: 10px;
  animation: 'slideFromLeft 1s ease forwards'
}

@keyframes slideFromLeft {
  from {
    opacity: 0;
    transform: translateX(-50%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

@media (max-width: 768px) {
  .icons-container {
    position: static;
    transform: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
}

.blink_effect {
  color: red;
  font-size: 20 px;
  animation-name:blink;
  animation-duration:2s;
  animation-timing-function:ease-in;
  animation-iteration-count:Infinite;
}  

@keyframes blink{
  0%{color:red;}
  50%{color:rgb(243, 197, 197);}
  100%{color:red;}
}


/* Estilos Landing Page */

#tsparticles canvas {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
}